<template>
    <div class="AuxilLedgerList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="auxilToolFram" ref="auxilToolFram">
                    <!-- <el-form-item label="标签">
                        <el-input v-model="auxilToolFram.auxCode" placeholder="请输入标签查询"></el-input>
                    </el-form-item> -->
                    <el-form-item label="工具编号">
                        <el-input v-model="auxilToolFram.auxNum" placeholder="请输入工器具编号查询"></el-input>
                    </el-form-item>
                    <el-form-item label="工具名称">
                        <el-input v-model="auxilToolFram.auxName" placeholder="请输入工具名称查询"></el-input>
                    </el-form-item>
                    <el-form-item label="工具分类">
                        <el-select placeholder="请选择工器具分类名称" v-model="auxilToolFram.classCode">
                            <el-option v-for="(item, index) in assistClassList" :key="index" :label="item.className"
                                :value="item.classCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="规格型号">
                        <el-input v-model="auxilToolFram.auxType" placeholder="请输入工器具编号查询"></el-input>
                    </el-form-item>
                   
                    <!-- <el-form-item label="状态">
                        <el-select placeholder="请选择工器具状态" v-model="auxilToolFram.status">
                            <el-option v-for="toolStatus in toolStatusList" :key="toolStatus.pKey" :label="toolStatus.pValue"
                                :value="toolStatus.pKey"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item>
                        <el-button  class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>

            </div>

            <!-- 列表 -->
            <el-table :data="auxiliaryList" border  style="width: 100%; font-size: 13px;" v-loading="loading"
                element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="className" label="工具分类" align="center"> </el-table-column>
                <el-table-column prop="auxName" label="工具名称" align="center">
                </el-table-column>
                <el-table-column prop="auxType" label="工具规格型号" align="center">
                </el-table-column>
                <el-table-column prop="auxNum" label="工器具编号" align="center">
                </el-table-column>
                <el-table-column prop="whCodeName" label="所在库房" align="center" width="80">
                </el-table-column>
                <el-table-column prop="location" label="存放位置" align="center">
                </el-table-column>
                <el-table-column prop="expirationDate" label="保质期/有效期（年）" align="center">
                </el-table-column>
                <el-table-column prop="produceDate" label="出厂日期" align="center">
                </el-table-column>
                <el-table-column prop="lastTestDate" label="上次试验日期" align="center">
                </el-table-column>
                <el-table-column prop="nextTestDate" label="下次试验日期" align="center">
                </el-table-column>
                <el-table-column prop="status_text" label="状态" align="center">
                </el-table-column>
                <el-table-column prop="auxPzbz" label="配置标准" align="center">
                </el-table-column>
                <el-table-column prop="auxXypz" label="现有配置" align="center">
                </el-table-column>

                <el-table-column label="操作" align="center" width="80">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="详情"
                            @click="handleDetail(scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="auxilToolFram.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="auxilToolFram.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>
<script>

import { getAssistClassAll } from '@/api/assistClass.js'
import { searchScrapInfo, getAuxilStatus } from '@/api/auxilInfo.js'
export default {
    data() {
        return {
            auxiliaryList: [],
            assistClassList: [],
            toolStatusList: [],
            auxilToolFram: {
                auxCode: null,
                classCode: null,
                auxName: null,
                auxType: null,
                auxNum: null,
                pageNum: 1,
                pageSize: 10,
            },
            total: 0,
            loading: false

        }
    },
    mounted() {
        this.loadToolStatus()
        this.loadToolClass()
        this.loadAuxiliaryInfo()
    },
    methods: {

        handleSizeChange(val) {
            this.auxilToolFram.pageSize = val;
            this.auxilToolFram.pageNum = 1;
            this.loadAuxiliaryInfo();
        },
        handleCurrentChange(val) {
            this.auxilToolFram.pageNum = val;
            this.loadAuxiliaryInfo();
        },

        async loadToolClass() {
            await getAssistClassAll().then(res => {
                if (res.code === '000000') {
                    this.assistClassList = res.t
                }
            })
        },
        async loadToolStatus() {
            await getAuxilStatus().then(res => {
                if (res.code === '000000') {
                    this.toolStatusList = res.data
                }
            })
        },

        loadAuxiliaryInfo() {
            this.loading = true
            searchScrapInfo(this.auxilToolFram).then(res => {
                setTimeout(() => {
                    this.loading = false
                    if (res.code === '000000') {
                        this.auxiliaryList = res.t.list
                        this.total = res.t.total
                        var count = 1;
                        this.auxiliaryList.forEach((item) => {
                            item.seq = count++
                            switch (item.status) {
                                case '00':
                                    item.status_text = '已入库'
                                    break;
                                case '01':
                                    item.status_text = '已出库'
                                    break;
                                case '02':
                                    item.status_text = '使用未返回'
                                    break;
                                case '03':
                                    item.status_text = '报废'
                                    break;
                                default:
                                    item.status_text = ''
                                    break;
                            }

                        })
                    }
                }, 500)
            })
        },


        handleDetail({ id }){
            this.$router.push({
                name: 'auxilScrap-detail',
                params: {
                    id
                }
            })
        },

        //搜索
        handleSearch() {
            this.loadAuxiliaryInfo(this.auxilToolFram);
        },
        //重置
        handleReset() {
            this.auxilToolFram = {};
            this.loadAuxiliaryInfo(this.auxilToolFram);
        }

    }
}
</script>

<style lang="scss" scoped>
.AuxilLedgerList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

}
</style>